import {Injectable} from "@angular/core";
import {environment} from '../../environments/environment';
import {HttpClient} from "@angular/common/http";
import {from, of, map, Observable, delay} from "rxjs";
import {shareReplay} from "rxjs/operators";



export interface Alert {
  "alert_info": string;
  "alert_type": string;
  "assigned_nurse_id": number;
  "client_id": number;
  "created_at": string;
  "created_at_utc": string;
  "days_with_readings": number;
  "alert_id": number;
  "last_call_date": string | null;
  "nurse_action": string | null;
  "patient_name": string;
  "priority": string | null;
  "age": number | null;
  "phone": string | null;
  "priority_ord" : number;
}

export interface Alerts {
  alert:[]
}

export interface AlertsResponse {
  message: Alert[];
}

export interface AlertsResolve {
  message: number;
  status: number;
}



@Injectable({providedIn: 'root'})
export class DashboardService {

  url:string = `${environment.API_URL}`

  constructor(private http: HttpClient,) {
  }

  getAlerts(endpoint: string, is_mocked: boolean = false ): Observable<AlertsResponse> {

    if(is_mocked){
      return of<AlertsResponse>({
          "message":  [
            {
              "alert_id": 1,
              "alert_info": "The patient has missing readings since 7 days",
              "alert_type": "Follow-up Required",
              "assigned_nurse_id": 3174,
              "client_id": 2342,
              "created_at": "2023-02-28T07:23:25",
              "created_at_utc": "2023-02-28T07:23:25",
              "days_with_readings": 0,
              "last_call_date": "2023-05-28T21:00:00",
              "nurse_action": null,
              "patient_name": "Test 1 Onboarding",
              "priority": null,
              "age": 0,
              "phone": "1234567890",
              "priority_ord": 10
            },
            ]
        })
    }

    const url = `${this.url}/${endpoint}`;
    return this.http.get<AlertsResponse>(url);
  } //end getAlerts

  getAlertsByUserOld(endpoint: string, user_id: number, is_mocked: boolean = false ): Observable<AlertsResponse> {

    if(is_mocked){
      return of<AlertsResponse>({
        "message":  [
          {
            "alert_id": 1,
            "alert_info": "The patient has missing readings since 7 days",
            "alert_type": "Follow-up Required",
            "assigned_nurse_id": 3174,
            "client_id": 2342,
            "created_at": "2023-02-28T07:23:25",
            "created_at_utc": "2023-02-28T07:23:25",
            "days_with_readings": 0,
            "last_call_date": "2023-05-28T21:00:00",
            "nurse_action": null,
            "patient_name": "Test 1 Onboarding",
            "priority": null,
            "age": 0,
            "phone": "1234567890",
            "priority_ord": 10
          },]
      })
    }

    const url = `${this.url}/${endpoint}/${user_id}`;
    return this.http.get<AlertsResponse>(url).pipe(
      shareReplay()
    );
  } //end getAlerts

  getAlertsByUser(endpoint: string, user_id: number, is_mocked: boolean = false ): Observable<Alert[]> {

    if(is_mocked){
      return of<Alert[]>(
        [
          {
            "alert_id": 1,
            "alert_info": "The patient has missing readings since 7 days",
            "alert_type": "Follow-up Required",
            "assigned_nurse_id": 3174,
            "client_id": 2342,
            "created_at": "2023-02-28T07:23:25",
            "created_at_utc": "2023-02-28T07:23:25",
            "days_with_readings": 0,
            "last_call_date": "2023-05-28T21:00:00",
            "nurse_action": null,
            "patient_name": "Test 1 Onboarding",
            "priority": null,
            "age": 0,
            "phone": "1234567890",
            "priority_ord": 10
          },
          ]

        // patient_name nurse_action  alert_info days_with_readings last_call_date total_time_spend priority(icons)

      ).pipe(
        delay(2000)
      )
    }

    const url = `${this.url}/${endpoint}/${user_id}`;
    return this.http.get<AlertsResponse>(url).pipe(
      shareReplay(),
      map(
        res => {
          return res.message;
        }
      )
    );
  } //end getAlerts

  // @ts-ignore
  getAlertsResolveByUser(endpoint: string, user_id: number, alert_id: number): Observable<AlertsResolve>{

    const url = `${this.url}/${endpoint}/${user_id}/${alert_id}`;
    return this.http.get<AlertsResolve>(url);

} //end getAlerts



} //
