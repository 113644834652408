import {Injectable} from "@angular/core";
import {environment} from '../../environments/environment';
import {HttpClient} from "@angular/common/http";
import {of} from "rxjs";



export interface UsersPostInterfaceData {
  allow:[]
}

export interface postUserInterfaceData {
  id: number;
  email: string;
  name: string;
}

export interface putUserInterfaceData {
  username: string;
  email: string;
  group: string;
  resend: boolean;
}

@Injectable({providedIn: 'root'})
export class UsersService {

  url:string = `${environment.API_URL}`

  constructor(private http: HttpClient,) {
  }

  getUsers(endpoint: string, is_mocked: boolean = false ){

    // if(is_mocked){
    //   return of([
    //     {user_id: 'uid123451', name: 'foo1', email: 'test@test.com'},
    //     {user_id: 'uid123452', name: 'foo2', email: 'test1@test.com'},
    //     {user_id: 'uid123453', name: 'foo3', email: 'test2@test.com'},
    //     {user_id: 'uid123454', name: 'foo4', email: 'test3@test.com'},
    //     {user_id: 'uid123455', name: 'foo5', email: 'test4@test.com'},
    //     {user_id: 'uid123456', name: 'foo6', email: 'test5@test.com'}])
    // }

    const url = `${this.url}/${endpoint}`;
    console.log('adfadfadfadfadfadf',url);
    return this.http.get(url);
  } //end login

  deleteUser(endpoint: string){
    const url = `${this.url}/${endpoint}`;
    return this.http.delete(url);
  } //end login

  postUser(endpoint: string, data: postUserInterfaceData){
    return this.http.post(`${this.url}/${endpoint}`, data,{observe: 'response'});
  }

  putUser(endpoint: string, data: putUserInterfaceData, is_mocked: boolean = false ) {
    return this.http.put(`${this.url}/${endpoint}`, data,{observe: 'response'});
  }



} //
