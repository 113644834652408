import {Component, inject} from '@angular/core';
import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
import { AsyncPipe } from '@angular/common';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { MatIconModule } from '@angular/material/icon';
import {Observable} from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';

import {LoginService} from "../auth/login/login.service";
import {MatMenuModule} from "@angular/material/menu";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";

@Component({
  selector: 'app-main-nav',
  templateUrl: './main-nav.component.html',
  styleUrls: ['./main-nav.component.css'],
  standalone: true,
  imports: [
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatListModule,
    MatIconModule,
    AsyncPipe,
    MatMenuModule,
  ]
})
export class MainNavComponent {

  constructor(
    private loginService: LoginService
    ) {}  // end constructor

  private breakpointObserver = inject(BreakpointObserver);
  // https://v6.material.angular.io/cdk/layout/overview
  // Small: 0px - 599px is the breakpoint for mobile devices.
  isHandset$: Observable<boolean> = this.breakpointObserver.observe([Breakpoints.Small, Breakpoints.XSmall, Breakpoints.HandsetPortrait, Breakpoints.HandsetLandscape])
    .pipe(
      takeUntilDestroyed(),
      map(result => {
        return result.matches
      }),
      shareReplay()
    );

  logout(){
    this.loginService.logout();
  } //end logout


} // end MainNavComponent
