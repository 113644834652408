import {Routes} from '@angular/router';
import {LoginComponent} from "./auth/login/login.component";
import {MfaComponent} from "./auth/mfa/mfa.component";
import {DashboardComponent} from "./dashboard/dashboard.component";
import {AuthGuard, NotAuthGuard, SuperAdminGuard} from './auth.guard';
import {QrCodeComponent} from "./auth/qr-code/qr-code.component";
import {NewPasswordRequiredComponent} from "./auth/new-password-required/new-password-required.component";
import {ForgotPasswordComponent} from "./auth/forgot-password/forgot-password.component";
import {
  ForgotPasswordConfirmationComponent
} from "./auth/forgot-password-confirmation/forgot-password-confirmation.component";
import {UsersComponent} from "./users/users.component";
import {CelTestComponent} from "./cel-test/cel-test.component";
import {CelDetailsTestComponent} from "./cel-details-test/cel-details-test.component";

export const routes: Routes = [
  { path: '', redirectTo: '/login', pathMatch: 'full'},
  { path: 'login',  component: LoginComponent, canActivate: [NotAuthGuard]},
  { path: 'mfa',  component: MfaComponent, canActivate: [NotAuthGuard]},
  { path: 'qrcode',  component: QrCodeComponent, canActivate: [NotAuthGuard]},
  { path: 'new-password-required',  component: NewPasswordRequiredComponent, canActivate: [NotAuthGuard]},
  { path: 'forgot-password',  component: ForgotPasswordComponent, canActivate: [NotAuthGuard]},
  { path: 'forgot-password-confirmation',  component: ForgotPasswordConfirmationComponent, canActivate: [NotAuthGuard]},
  { path: 'users',  component: UsersComponent,  canActivate: [SuperAdminGuard]},
  { path: 'cal',  component: CelTestComponent, canActivate: [SuperAdminGuard]},
  { path: 'cal-details',  component: CelDetailsTestComponent, canActivate: [SuperAdminGuard]},
  { path: 'dashboard/:user_id',  component: DashboardComponent,  canActivate: [AuthGuard]},

];


