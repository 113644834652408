import {Component, DestroyRef, inject, OnInit} from '@angular/core';
import { CommonModule } from '@angular/common';
import {ModelValidators} from "../../validators/validators";
import {FormBuilder, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {LoginService} from "../login/login.service";
import {ActivatedRoute, Router} from "@angular/router";
import {MatCardModule} from "@angular/material/card";
import {MatToolbarModule} from "@angular/material/toolbar";
import {MatProgressBarModule} from "@angular/material/progress-bar";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatIconModule} from "@angular/material/icon";
import {InputPasswordComponent} from "../../resusable/input-password/input-password.component";
import {MatButtonModule} from "@angular/material/button";
import {environment} from "../../../environments/environment";
import {SnackBarService} from "../../objectPassingService";
import {Subject, takeUntil} from "rxjs";

@Component({
  selector: 'app-new-password-required',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, MatCardModule, MatToolbarModule, MatProgressBarModule, MatFormFieldModule, MatIconModule, InputPasswordComponent, MatButtonModule],
  templateUrl: './new-password-required.component.html',
  styleUrl: './new-password-required.component.css'
})
export class NewPasswordRequiredComponent implements OnInit {

  private destroyRef = inject(DestroyRef);
  destroyed = new Subject();

  is_loading = false;
  is_form_error: boolean = false;
  form_error_message: string = "";
  is_auth_error: boolean = false;

  myForm: FormGroup = this.fb.group({
    password: ['', [Validators.required, ModelValidators.passwordControls]]
  });


  constructor(public router: Router,
              public route: ActivatedRoute,
              private fb: FormBuilder,
              private loginService: LoginService,
              private snackBarService: SnackBarService,
              ) { }

  ngOnInit(): void {

    this.destroyRef.onDestroy(() => {
      this.destroyed.next('');
      this.destroyed.complete();
    });

  } // end ngOnInit


  goBack(){
    window.history.back();
  }

  onFocus(){
    // console.log('onFocus')
    // this.is_form_error = false;
    // this.form_error_message = "";
  }

  onSubmit() {

    this.is_loading = true;
    this.is_form_error = false;
    const data = this.myForm.value;
    const query_parms = this.route.snapshot.queryParamMap;
    const session = query_parms.get('session');
    const email = query_parms.get('email');



    const url = `${environment.API_AUTH_URL}/auth/change-password`


    //let authObs: Observable<AuthResponseData>
    this.loginService.forcedNewPassword(url,email,data['password'], session).pipe(takeUntil(this.destroyed)).subscribe(
      res => {
        //{message: 'Incorrect username or password.', status_code: 400}
        console.log('res', res)

        if (res.ChallengeName=='MFA_SETUP'){
          this.is_loading = false;
          this.snackBarService.announceSackBar('Password changed successfully.');
          this.router.navigate([ 'qrcode' ], { queryParams: {
              session:  res.session,
              qr: res['qr']} })
        }

        this.is_loading = false;
        // if (res.ChallengeName=='SOFTWARE_TOKEN_MFA'){
        //   this.router.navigate([ 'mfa' ], { queryParams: {
        //       session:  res.session,
        //       username: data['username']} })
        // }
        // if (res['status_code'] == 400) {
        //   this.is_form_error = true;
        //   this.form_error_message = res['message']
        // } else {
        //   //navigate to agency from auth.services
        //   this.router.navigate(['/agencys']);
        // } //end if
      },
      errorMessage => {
        this.is_loading = false;
        this.is_auth_error = true;
        this.is_form_error = true;
        this.form_error_message = 'Invalid code received for user' //res['message']
      },
      () => {
        console.log('auth completed')
      }
    ); //end subscribe

  } //end onSubmit

} //end NewPasswordRequiredComponent
