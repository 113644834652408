<mat-progress-bar color="accent" mode="indeterminate" *ngIf="is_loading"></mat-progress-bar>

<div style="width: 550px;"
  ngClass.xs="page-margin-xs"
  ngClass.sm="page-margin-sm"
  ngClass="page-margin">

  <div>
    <mat-list>
      <mat-list-item>Users</mat-list-item>
      <mat-divider></mat-divider>
    </mat-list>
  </div>

  <!--  <div style="margin-top: 20px; margin-bottom: 20px;">-->
  <!--    <button mat-fab aria-label="Add User" (click)="addUser(false, {})" matTooltip="Add User"-->
  <!--            matTooltipPosition="right">-->
  <!--      <mat-icon class="add-button">add</mat-icon>-->
  <!--    </button>-->
  <!--  </div>-->

  <mat-form-field>
    <mat-label>Filter</mat-label>
    <input matInput (keyup)="applyFilter($event)" placeholder="Ex. Mia" #input>
  </mat-form-field>

  <div ngClass="table-width" ngClass.xs="table-width-xs" ngClass.sm="table-width-sm">

    <mat-card class="mat-elevation-z24">

      <table mat-table [dataSource]="dataSource" matSort>

        <!-- User Id Column -->
        <ng-container matColumnDef="user_id">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>User ID</th>
          <td mat-cell *matCellDef="let row">{{row.user_id}}</td>
        </ng-container>

        <!-- Email Column -->
        <ng-container matColumnDef="email">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Email</th>
          <td mat-cell *matCellDef="let row">{{row.email}}</td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
          <td mat-cell *matCellDef="let row">{{row.name}}</td>
        </ng-container>

        <!-- View Column -->
        <ng-container matColumnDef="View">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>View</th>
          <td mat-cell *matCellDef="let row">
            <button mat-icon-button class="any-icon-primary table-icon" (click)="viewData(row)">
              <mat-icon color="accent">visibility</mat-icon>
            </button>
          </td>
        </ng-container>


        <!--  &lt;!&ndash; Edit Column (click)="addAccount(true,row.Id)" &ndash;&gt;-->
        <!--  <ng-container matColumnDef="Edit">-->
        <!--    <th mat-header-cell *matHeaderCellDef mat-sort-header>Edit</th>-->
        <!--    <td mat-cell *matCellDef="let row">-->
        <!--      <button mat-icon-button class="any-icon-primary table-icon" (click)="addUser(true,  row)">-->
        <!--        <mat-icon color="accent" style="cursor: pointer;">edit</mat-icon>-->
        <!--      </button>-->
        <!--    </td>-->
        <!--  </ng-container>-->

        <!--  &lt;!&ndash; Delete Column &ndash;&gt;-->
        <!--  <ng-container matColumnDef="Delete">-->
        <!--    <th mat-header-cell *matHeaderCellDef mat-sort-header>Delete</th>-->
        <!--    <td mat-cell *matCellDef="let row">-->
        <!--      <button mat-icon-button class="warn-icon-primary table-icon" (click)="deleteRow(row)">-->
        <!--        <mat-icon color="warn" style="cursor: pointer;">delete</mat-icon>-->
        <!--      </button>-->
        <!--    </td>-->
        <!--  </ng-container>-->
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="element-row"
          (click)="getTableRecord(row)"></tr>
      </table>


      <mat-paginator [pageSizeOptions]="[5, 10, 20, 50, 100, 250, 500]"
        [pageSize]="100"
        showFirstLastButtons
        aria-label="Select page of periodic elements">
      </mat-paginator>

    </mat-card>
  </div>
</div>