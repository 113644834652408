<ng-container *ngIf="is_logged_in && !superadmin_view">
  <app-main-nav>
    <router-outlet></router-outlet>
  </app-main-nav>
</ng-container>

<ng-container *ngIf="is_logged_in && superadmin_view">
  <mat-toolbar color="accent">
    <span class="ref"></span>
    <span class="example-spacer"></span>
    <mat-menu class="dropdown-menu" #appMenu="matMenu">
      <button mat-menu-item (click)="calendarDetailsRedirect()">
        <mat-icon class="add-button">event</mat-icon>
        Registration Status
      </button>
      <button mat-menu-item (click)="calendarRedirect()">
        <mat-icon class="add-button">calendar_month</mat-icon>
        Calendar
      </button>
      <button mat-menu-item (click)="logout()">
        <mat-icon class="add-button">logout</mat-icon>
        Logout
      </button>
    </mat-menu>
    <button mat-icon-button [matMenuTriggerFor]="appMenu">
      <mat-icon>more_vert</mat-icon>
    </button>

  </mat-toolbar>
  <router-outlet></router-outlet>
</ng-container>

<ng-container *ngIf="!is_logged_in">
  <mat-toolbar color="accent"><span class="ref"></span></mat-toolbar>
  <router-outlet></router-outlet>
</ng-container>


<!--@if (is_logged_in) {-->
<!--<app-main-nav>-->
<!--<router-outlet></router-outlet>-->
<!--</app-main-nav>-->
<!--} @else {-->
<!--<mat-toolbar color="primary">HEALENT</mat-toolbar>-->
<!--<router-outlet></router-outlet>-->
<!--}-->

