<form [formGroup]="myForm" (ngSubmit)="onSubmit()" style="margin-top: 30px;" novalidate>

  <div class="flex-container">

    <mat-card style="width: 350px; height: 100%;">

      <mat-toolbar color="accent" class="mat-elevation-z16">
        <mat-toolbar-row>
          <span>Login</span>
        </mat-toolbar-row>
      </mat-toolbar>
      <mat-progress-bar *ngIf="is_loading" color="accent" mode="indeterminate" style="margin-top: 0"></mat-progress-bar>


      <mat-card-content>
        <app-input-email placeholder="Enter your username:" value="" formControlName="email" type="text"></app-input-email>
        <app-input-password placeholder="Enter your password" formControlName="password"></app-input-password>
      </mat-card-content>
      <div *ngIf="is_form_error">
        <mat-error style="margin-left: 10px; margin-bottom: 5px">
          <strong>{{form_error_message}}</strong>
        </mat-error>
      </div>

      <button mat-raised-button color="accent" [disabled]="!myForm.valid || is_loading" type="submit" style="margin: 25px">Login</button>
      <div class="flex-container">
        <a style="margin-left: 10px; margin-bottom: 10px;" routerLinkActive="active" [routerLink]="['/forgot-password']">Forgot Password</a>
      </div>


    </mat-card>

  </div>



</form>