
import { Injectable } from '@angular/core';
import {Subject, BehaviorSubject, Observable, finalize, of, tap, concatMap} from 'rxjs';


@Injectable({providedIn: 'root'})
export class CurrentLoggedInBehaviorService {

  constructor() {
  }

  private currentLoggedInAnnouncedBehaviorSource = new BehaviorSubject<boolean>(false);
  // Observable string streams
  currentLoggedInBehaviorAnnounced$ = this.currentLoggedInAnnouncedBehaviorSource.asObservable();

  // Service message commands
  announceCurrentLoggedInBehavior(current: boolean) {
    this.currentLoggedInAnnouncedBehaviorSource.next(current);
  }
}


@Injectable({providedIn: 'root'})
export class SuperAdminViewBehaviorService {

  constructor() {
  }

  private currentSuperAdminViewAnnouncedBehaviorSource = new BehaviorSubject<boolean>(false);
  // Observable string streams
  currentSuperAdminViewBehaviorAnnounced$ = this.currentSuperAdminViewAnnouncedBehaviorSource.asObservable();

  // Service message commands
  announceSuperAdminViewBehavior(current: boolean) {
    this.currentSuperAdminViewAnnouncedBehaviorSource.next(current);
  }
}


@Injectable({providedIn: 'root'})
export class CurrentRefreshTokenService {

  constructor() { }

  private currentRefreshTokenAnnouncedSource = new Subject<boolean>();
  // Observable string streams
  currentRefreshTokenAnnounced$ = this.currentRefreshTokenAnnouncedSource.asObservable();
  // Service message commands
  announceRefreshToken(is_logged_in: boolean) {
    this.currentRefreshTokenAnnouncedSource.next(is_logged_in);
  }

}


@Injectable({providedIn: 'root'})
export class SnackBarService {

  constructor() { }

  private snackbarAnnouncedSource = new Subject<string>();
  // Observable string streams
  snackbarAnnounced$ = this.snackbarAnnouncedSource.asObservable();
  // Service message commands
  announceSackBar(snackbar: string) {
    this.snackbarAnnouncedSource.next(snackbar);
  }

}


@Injectable({providedIn: 'root'})
export class LoadingService {

  /*
  https://www.youtube.com/watch?v=czf4Bh8eNPA
  concantMap is super cool
   */

  private loadingSubject = new BehaviorSubject<boolean>(false);

  loading$: Observable<boolean> = this.loadingSubject.asObservable();

  showLoadingUntilCompleted<T>(obs$: Observable<T>): Observable<T> {  // <T> is a generic type
   return of(null).pipe(
     tap(() => this.loadingOn()),
     concatMap(() => obs$),
     finalize(() => this.loadingOff())

   )
  }

  loadingOn() {
    this.loadingSubject.next(true);

  }

  loadingOff() {
    this.loadingSubject.next(false);

  }

} // end LoadingService


@Injectable({providedIn: 'root'})
export class LoadingMessagesService {

  private loadingSubject = new BehaviorSubject<boolean>(false);

  loading$: Observable<boolean> = this.loadingSubject.asObservable();

  showLoadingUntilCompleted<T>(obs$: Observable<T>): Observable<T> {  // <T> is a generic type
    return of(null).pipe(
      tap(() => this.loadingOn()),
      concatMap(() => obs$),
      finalize(() => this.loadingOff())

    )
  }

  loadingOn() {
    this.loadingSubject.next(true);

  }

  loadingOff() {
    this.loadingSubject.next(false);

  }

} // end LoadingService


@Injectable({providedIn: 'root'})
export class LoadingCalendarService {

  private loadingSubject = new BehaviorSubject<boolean>(false);

  loading$: Observable<boolean> = this.loadingSubject.asObservable();

  showLoadingUntilCompleted<T>(obs$: Observable<T>): Observable<T> {  // <T> is a generic type
    return of(null).pipe(
      tap(() => this.loadingOn()),
      concatMap(() => obs$),
      finalize(() => this.loadingOff())

    )
  }

  loadingOn() {
    this.loadingSubject.next(true);

  }

  loadingOff() {
    this.loadingSubject.next(false);

  }

} // end LoadingService












