import {ChangeDetectorRef, Component, DestroyRef, inject, OnInit, ViewChild} from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatTooltipModule } from '@angular/material/tooltip';


import {FullCalendarComponent, FullCalendarModule} from '@fullcalendar/angular';
import {Calendar, DateSelectArg, EventApi, EventClickArg, EventHoveringArg, EventInput} from '@fullcalendar/core';
import { CalendarOptions } from '@fullcalendar/core'; // useful for typechecking
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction';
import monthGridPlugin from '@fullcalendar/daygrid';
import listPlugin from '@fullcalendar/list';
import {co} from "@fullcalendar/core/internal-common";
import {MatProgressBarModule} from "@angular/material/progress-bar";
import {MatTooltip} from "@angular/material/tooltip";
import {CelTestService} from "./cel-test.service";
import {ActivatedRoute, Router} from "@angular/router";
import {Subject, takeUntil} from "rxjs";

let eventGuid = 0;
function createEventId() {
  return String(eventGuid++);
}

@Component({
  selector: 'app-cel-test',
  standalone: true,
  imports: [CommonModule, FullCalendarModule, MatTooltipModule, MatProgressBarModule],
  templateUrl: './cel-test.component.html',
  styleUrls: ['./cel-test.component.css'],

})
export class CelTestComponent implements OnInit{

  //https://fullcalendar.io/docs/angular
  //@ViewChild('calendar') calendarComponent: FullCalendarComponent;

  private destroyRef = inject(DestroyRef);
  destroyed_get_cal = new Subject();

  is_loading = true;
  @ViewChild('calendar') calendarComponent: FullCalendarComponent | undefined;

  currentEvents: EventApi[] = [];
  tooltipMessage: string = '';

  calendarOptions: CalendarOptions = {
    initialView: 'dayGridMonth',
    plugins: [
      interactionPlugin,
      dayGridPlugin,
      timeGridPlugin,
      listPlugin,
    ],
    weekends: true,
    editable: false,
    selectable: false,
    selectMirror: true,
    dayMaxEvents: true,
    headerToolbar: {
      left: 'prev,next today',
      center: 'title',
      right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek'
    },
    events: [],
    select: this.handleDateSelect.bind(this),
    eventClick: this.handleEventClick.bind(this),
    eventsSet: this.handleEvents.bind(this),
    eventAdd:  this.handleEventAdd.bind(this),
    eventChange: this.handleEventChange.bind(this),
    eventRemove: this.handleEventRemove.bind(this),
    eventMouseEnter: this.handleEventMouseEnter.bind(this),
    eventMouseLeave: this.handleEventMouseLeave.bind(this),
    //schedulerLicenseKey: 'CC-Attribution-NonCommercial-NoDerivatives'
  };

  eventsPromise: Promise<EventInput[]> | undefined;

  constructor(private changeDetector: ChangeDetectorRef,
              public router: Router,
              private route: ActivatedRoute,
              private celTestService: CelTestService

  ) {
  }

  ngOnInit() {
    //this.calendarOptions.schedulerLicenseKey = 'CC-Attribution-NonCommercial-NoDerivatives';

    this.destroyRef.onDestroy(() => {
      this.destroyed_get_cal.next(true);
      this.destroyed_get_cal.complete();
    });

    this.getEvents()

  } // end of ngOnInit

  getEvents() {

    this.celTestService.getCalendar('calendar', false).pipe(takeUntil(this.destroyed_get_cal)).subscribe(
      (data) => {
        console.log('data',data['message']);
        this.calendarOptions.events = data['message'];

        this.is_loading = false;
        //this.loadEvents(data);
      },
      (error) => {
        console.log('error',error);
        this.is_loading = false;
      }
    ) // end of subscribe

    // let event_list = [
    //   { title: 'David Montgomery',
    //     date: '2024-03-03T13:00:00',
    //     // end: '2024-03-03',
    //     backgroundColor: 'green',
    //     borderColor: 'green',
    //     textColor: 'white',
    //     allDay: false,
    //     // editable: true,
    //     // startEditable: true,
    //     // durationEditable: true,
    //     // resourceEditable: true,
    //     // overlap: true,
    //     // constraint: 'businessHours',
    //   },
    //   { title: 'Anoop',
    //     date: '2024-03-03T15:00:00',
    //     // end: '2024-03-03',
    //     backgroundColor: 'yellow',
    //     borderColor: 'yellow',
    //     textColor: 'white',
    //     allDay: false,
    //     groupId: 'a',
    //     id: 'user_id_1',
    //     //mouseEnterInfo: 'mouseEnterInfo',
    //     // editable: true,
    //     // startEditable: true,
    //     // durationEditable: true,
    //     // resourceEditable: true,
    //     // overlap: true,
    //     // constraint: 'businessHours',
    //   },
    //   { title: 'Dude Dudder',
    //     date: '2024-03-03T17:00:00',
    //     // end: '2024-03-03',
    //     backgroundColor: 'black',
    //     borderColor: 'black',
    //     textColor: 'white',
    //     allDay: false,
    //     groupId: 'a',
    //     id: 'user_id_3',
    //     //mouseEnterInfo: 'mouseEnterInfo',
    //     // editable: true,
    //     // startEditable: true,
    //     // durationEditable: true,
    //     // resourceEditable: true,
    //     // overlap: true,
    //     // constraint: 'businessHours',
    //   },
    // ];
    //
    // //https://fullcalendar.io/docs/event-object
    // this.calendarOptions.events = event_list;
    //
    // this.is_loading = false;

  }

  handleDateSelect(selectInfo: DateSelectArg) {
    const title = prompt('Please enter a new title for your event');
    const calendarApi = selectInfo.view.calendar;

    calendarApi.unselect(); // clear date selection

    if (title) {
      calendarApi.addEvent({
        id: createEventId(),
        title,
        start: selectInfo.startStr,
        end: selectInfo.endStr,
        allDay: selectInfo.allDay
      });
    }
  }

  getEventById(id: string) {

    //this.getEventById("a1")

    let calendarApi = this.calendarComponent!.getApi();
    const event = calendarApi.getEventById(id);
    console.log('getEventById',event);
  }

  handleEventClick(clickInfo: EventClickArg) {
    console.log('handleEventClick',clickInfo.event);
    // if (confirm(`Are you sure you want to delete the event '${clickInfo.event.title}'`)) {
    //   clickInfo.event.remove();
    // }

    this.getEventById("a1")

  } // end of handleEventClick

  handleEvents(events: EventApi[]) {
    console.log('events::::::::',events);
    // this.currentEvents = events;
    // this.changeDetector.detectChanges();
  }

  handleEventAdd(event: any) {
    console.log('event added',event);
  }

  handleEventRemove(event: any) {
    console.log('event removed',event);
  }

  handleEventChange(event: any) {
    console.log('event changed',event);

    this.changeDetector.detectChanges();
  }
  // handleEventChange(events: EventApi[]) {
  //   console.log('event changed',events);
  //   //this.currentEvents = events;
  //   //this.changeDetector.detectChanges();
  // }

  handleEventMouseEnter(mouseEnterInfo: EventHoveringArg) {
    console.log('handleEventMouseEnter',mouseEnterInfo);
    this.tooltipMessage = mouseEnterInfo.event.title;
    //console.log('this.tooltipMessage',this.tooltipMessage);
    // Show tooltip
    // You can use mouseEnterInfo.el to get the HTML element of the event,
    // and mouseEnterInfo.event to get the event data
  }

  handleEventMouseLeave(mouseLeaveInfo: EventHoveringArg) {
    console.log('handleEventMouseLeave',mouseLeaveInfo);
    this.tooltipMessage = '';
  }


} // end of class CelTestComponent
