<mat-progress-bar color="accent" mode="indeterminate" *ngIf="is_loading"></mat-progress-bar>


<div style="width: 550px;"
     ngClass.xs="page-margin-xs"
     ngClass.sm="page-margin-sm"
     ngClass="page-margin">

  <div>
    <mat-list>
      <mat-list-item>Registration Status</mat-list-item>
      <mat-divider></mat-divider>
    </mat-list>
  </div>


  <mat-form-field>
    <mat-label>Filter</mat-label>
    <input matInput (keyup)="applyFilter($event)" placeholder="Ex. Mia" #input>
  </mat-form-field>

  <div ngClass="table-width" ngClass.xs="table-width-xs" ngClass.sm="table-width-sm">

    <mat-card class="mat-elevation-z24">

      <table mat-table [dataSource]="dataSource" matSort>

        <!-- User Id Column -->
        <ng-container matColumnDef="patient_id">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>EMR ID</th>
          <td mat-cell *matCellDef="let row">{{row.patient_id}}</td>
        </ng-container>

        <ng-container matColumnDef="full_name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
          <td mat-cell *matCellDef="let row">{{row.full_name}}</td>
        </ng-container>

        <ng-container matColumnDef="created_at">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Created</th>
          <td mat-cell *matCellDef="let row">{{row.created_at | date:'short'}}</td>
        </ng-container>

        <ng-container matColumnDef="updated_at">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Updated</th>
          <td mat-cell *matCellDef="let row">{{row.updated_at | date:'short'}}</td>
        </ng-container>

        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
          <td mat-cell *matCellDef="let row"  [style.color]="getColor(row.status)">{{row.status}}</td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="element-row"
            (click)="getTableRecord(row)"></tr>
      </table>


      <mat-paginator [pageSizeOptions]="[5, 10, 20, 50, 100, 250, 500]"
                     [pageSize]="20"
                     showFirstLastButtons
                     aria-label="Select page of periodic elements">
      </mat-paginator>

    </mat-card>
  </div>
</div>
