import { Injectable } from '@angular/core';
import {Router, CanActivate} from '@angular/router';
import { HttpClient } from '@angular/common/http';
import {LoginService} from "./auth/login/login.service";

// TODO: Create a not authorized page and redirect to it OR snackbar OR modal.. currently just redirecting to login page


@Injectable({providedIn: 'root'})
export class AuthGuard implements CanActivate {

  /*
  This is the route guard for the users that are not super admins
   */


  constructor(
              public http: HttpClient,
              private loginService: LoginService,
              ) {}


  canActivate() {

    if (localStorage.hasOwnProperty('id_token') && localStorage.hasOwnProperty('refresh_token')){

      console.log('auth guard: has id_token and refresh_token')

      let is_user_logged_in = this.loginService.isLoggedin()
      if (is_user_logged_in){
        console.log('auth guard: has a valid user');
        return true;
      }
    }
    console.log('auth guard: does not have id_token and refresh_token so we are logging out')
    this.loginService.logout();
    return false;

  } //end canActivate
}

@Injectable({providedIn: 'root'})
export class NotAuthGuard implements CanActivate {

  /*
  This is the route guard for the users that are not not authenticated
   */


  constructor(
              public http: HttpClient,
              private loginService: LoginService,
  ) {}


  canActivate() {

    if (localStorage.hasOwnProperty('id_token') && localStorage.hasOwnProperty('refresh_token')){

      console.log('auth guard: has id_token and refresh_token')

      let is_user_logged_in = this.loginService.isLoggedin()
      if (is_user_logged_in){
        console.log('auth guard: has a valid user and we are logging out - probably hit the back button to a non auth route');
        this.loginService.logout();
        return false;
      }
    }
    console.log('user is not authenticated so we are allowing them to continue for non auth routes')

    return true;

  } //end canActivate
}


@Injectable({providedIn: 'root'})
export class SuperAdminGuard {

  /*
  This is the route guard for the users that are super admins
   */

  constructor(private router: Router,
              public http: HttpClient,
              private loginService: LoginService,
  ) {}


  canActivate(){
    if (localStorage.hasOwnProperty('id_token') &&
      localStorage.hasOwnProperty('refresh_token') &&
      localStorage.hasOwnProperty('role') &&
      localStorage.getItem('role') === 'superadmin'){


      console.log('auth guard: has id_token and refresh_token')

      let is_user_logged_in = this.loginService.isLoggedin()  // we know the used is logged and had a valid token
      if (is_user_logged_in){
        console.log('auth guard: has a valid user');
        return true;
      }
    }
    console.log('auth guard: does not have id_token and refresh_token so we are loging out')
    this.loginService.logout();
    return false;
  }
}


// @Injectable({providedIn: 'root'})
// export class AdminGuard {
//
//   constructor(
//               public http: HttpClient,
//               private loginService: LoginService,
//   ) {}
//
//   canActivate(){
//     if (localStorage.hasOwnProperty('id_token') && localStorage.hasOwnProperty('refresh_token')){
//
//
//       console.log('auth guard: has id_token and refresh_token')
//
//       let is_user_logged_in = this.loginService.isLoggedin()  // we know the used is logged and had a valid token
//       if (is_user_logged_in){
//         console.log('auth guard: has a valid user');
//         return true;
//       }
//     }
//     console.log('auth guard: does not have id_token and refresh_token so we are loging out')
//     this.loginService.logout();
//     return false;
//   }
// }

// https://angular.io/api/router/CanActivate
// https://stackoverflow.com/questions/75564717/angulars-canactivate-interface-is-deprecated-how-to-replace-it
// const route: Route = {
//   path: 'admin',
//   canActivate: mapToCanActivate([AdminGuard]),
// };

// https://angular.io/api/router/CanActivateFn
