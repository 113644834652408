import {Injectable, NgZone} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {JwtHelperService} from "@auth0/angular-jwt";
import {Observable, retry, tap} from "rxjs";
import {CurrentLoggedInBehaviorService, CurrentRefreshTokenService, SuperAdminViewBehaviorService} from "../../objectPassingService";
import {Router} from "@angular/router";
import { CookieService } from 'ngx-cookie-service';
import {environment} from "../../../environments/environment";


//https://stackoverflow.com/questions/47236963/no-provider-for-httpclient


export interface LoginData {
  session: string;
  email: string;
  ChallengeName: string;
  qr?: string;
  mfa_code?: string;
}

export interface AuthResponseData {
  AccessToken: string;
  ExpiresIn: string;
  IdToken: string;
  RefreshToken: string;
  TokenType: string;
}

export interface ForgotPasswordData {
  email: string;
}

export interface ForgotPasswordConfirmationData {
  email: string;
  password: string;
  code: string;
}

export interface LoginFlowResponseData {
  session: string;
  username?: string;
  ChallengeName: string;
  qr?: string;
  enum?: string;
  message?: string;
  status_code?: number;
}

@Injectable({providedIn: 'root'})
export class LoginService{

  constructor(
    private http: HttpClient,
    public router: Router,
    private currentLoggedInBehaviorService: CurrentLoggedInBehaviorService,
    private currentRefreshTokenService: CurrentRefreshTokenService,
    private superAdminViewBehaviorService: SuperAdminViewBehaviorService,
    public ngZone: NgZone,
    private cookieService: CookieService
  ) {}


  storeCognitoTokens(AccessToken: string, IdToken: string, ExpiresIn: string, RefreshToken: string, TokenType: string){


    let my_role: string = "";
    const helper = new JwtHelperService();
    const decodedToken = helper.decodeToken(IdToken);
    const user_groups = decodedToken['cognito:groups'];

    if (user_groups){
      my_role=  user_groups[0];
    } else {
      my_role = "admin";
    }

    const user_id = decodedToken['custom:heal_user_id'];
    console.log('custom:heal_user_id', user_id);
    if (user_id){
      localStorage.setItem('user_id', user_id);
    }

    localStorage.setItem('id_token', IdToken);
    localStorage.setItem('refresh_token', RefreshToken);
    localStorage.setItem('access_token', AccessToken);
    localStorage.setItem('email', decodedToken['email']);
    localStorage.setItem('username', decodedToken['cognito:username']);
    localStorage.setItem('exp', decodedToken['exp']);
    localStorage.setItem('role', my_role);

    this.cookieService.set('Test', 'Hello World', 1, '/', environment.domain, true, 'Lax'); // Strict || Lax
    this.cookieService.set('Foo', 'Bar', 1, '/', environment.domain, true, 'Strict'); // Strict || Lax

    this.currentLoggedInBehaviorService.announceCurrentLoggedInBehavior(true);
    this.currentRefreshTokenService.announceRefreshToken(true);

  }

  handleAuthentication(AccessToken: string, IdToken: string, ExpiresIn: string, RefreshToken: string, TokenType: string) {
    // on login redirect to clients route
    this.storeCognitoTokens(AccessToken, IdToken, ExpiresIn, RefreshToken, TokenType);
    if (IdToken) {
      const role = localStorage.getItem('role');
      if (role === 'superadmin') {
        this.superAdminViewBehaviorService.announceSuperAdminViewBehavior(true);
        this.router.navigate(['/users']).then(r => console.log('redirect to admin users'));
      } else if (role === 'admin' ) {
        const user_id = localStorage.getItem('user_id');
        this.superAdminViewBehaviorService.announceSuperAdminViewBehavior(false);
        this.router.navigate(['/dashboard',user_id]).then(r => console.log('dashboard'));
      }
    }
  } //end handleAuthentication

  login(url: string, email: string, password: string):  Observable<LoginFlowResponseData>{
    return this.http.post<LoginData>(
      url,{
        username: email,
        password: password
      },
      {  headers: { 'Content-Type': 'application/json'} }
    ).pipe(
      retry(3)
    );
  }// end login

  mfa(url: string, email: string | null, session: string | null, mfa_code: string){
    return this.http.post<AuthResponseData>(
      url,{
        username: email,
        session: session,
        mfa_code: mfa_code
      },
      {  headers: { 'Content-Type': 'application/json'} }
    ).pipe(
      tap(resData => {
        this.handleAuthentication(resData.AccessToken, resData.IdToken, resData.ExpiresIn, resData.RefreshToken, resData.TokenType);
      })
    );
  }// end login

  forcedNewPassword(url: string, email: string | null, password: string | null, session: string | null){
    return this.http.post<LoginData>(
      url,{
        username: email,
        password: password,
        session: session
      },
      {  headers: { 'Content-Type': 'application/json'} }
    );
  } //end forcedNewPassword

  verifyMfaDevice(url: string, session: string | null, mfa_code: string){

    return this.http.post(
      url,{
        mfa_code: mfa_code,
        session: session
      },
      {  headers: { 'Content-Type': 'application/json'} })

  } //end verifyMfaDevice

  forgotPassword(url: string, email: string){
    return this.http.post<ForgotPasswordData>(
      url,{
        email: email,
      },
      {  headers: { 'Content-Type': 'application/json'} }
    );
  } //end forgotPassword

  forgotPasswordConfirmation(url: string, data: ForgotPasswordConfirmationData){
    return this.http.post<ForgotPasswordData>(
      url,data,
      {  headers: { 'Content-Type': 'application/json'} }
    );
  } //end forgotPassword

  refreshToken(url: string, username: string, refresh_token: string){
    console.log('refresh_token', refresh_token);
    console.log('username', username);
    console.log('url', url);
    return this.http.post<AuthResponseData>(
      url,{
        username: username,
        refresh_token: refresh_token
      },
      {  headers: { 'Content-Type': 'application/json'}
      }
    ).pipe(
      retry(3),
      tap(resData => {
        this.storeCognitoTokens(resData.AccessToken, resData.IdToken, resData.ExpiresIn, resData.RefreshToken, resData.TokenType);
      }, error => {
        console.log('error from refresh token 1c', error)
        // This is critial to logout the user if the refresh token is expired and there is no way to get a new one
        this.logout();
      })
    );
  }

  logout() {
    localStorage.clear()
    this.cookieService.deleteAll('/', environment.domain);
    this.currentLoggedInBehaviorService.announceCurrentLoggedInBehavior(false);
    this.currentRefreshTokenService.announceRefreshToken(false);
    this.ngZone.run(() => {
      this.router.navigate(['/login']).then(r => console.log('navigate to login'));
    });
  } //end logout

  isLoggedin() {

    //Used for reload of main-nav
    //Check if the user is logged in with a valid token and time left on token

    let is_loggedin = false;
    const id_token = localStorage.getItem("id_token");

    if (id_token != null){
      const exp = localStorage.getItem('exp')! as unknown as number;
      const helper = new JwtHelperService();
      const isExpired = helper.isTokenExpired(id_token);
      console.log('is_expired', isExpired)
      const current_time = new Date().getTime() / 1000;
      const expirationDuration = exp - current_time;
      if (expirationDuration>0){
        is_loggedin = true;
        this.currentLoggedInBehaviorService.announceCurrentLoggedInBehavior(true);
      } else {
        is_loggedin = false;
        this.currentLoggedInBehaviorService.announceCurrentLoggedInBehavior(false);
      }
    } else {
      is_loggedin = false;
      this.currentLoggedInBehaviorService.announceCurrentLoggedInBehavior(false);
    }
    return is_loggedin;

  } //end isLoggedin



}
