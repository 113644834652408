<mat-sidenav-container class="sidenav-container">
  <!--  <mat-sidenav #drawer class="sidenav" fixedInViewport-->
  <!--      [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"-->
  <!--      [mode]="(isHandset$ | async) ? 'over' : 'side'"-->
  <!--      [opened]="(isHandset$ | async) === false">-->
  <!--    <mat-toolbar color="primary">Menu</mat-toolbar>-->
  <!--    <mat-nav-list>-->
  <!--      <a mat-list-item >Link 1</a>-->
  <!--      <a mat-list-item >Link 2</a>-->
  <!--      <a mat-list-item >Link 3</a>-->
  <!--    </mat-nav-list>-->
  <!--  </mat-sidenav>-->
  <!-- NOTE: WE WILL REVERT BACK TO THE ABOVE ONCE WE HAVE SOMETHING TO PLACE IN SIDEBAR-->
  <mat-sidenav #drawer class="sidenav" fixedInViewport
    [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
    [mode]="(isHandset$ | async) ? 'over' : 'side'"
    [opened]="false">
    <mat-toolbar color="accent">Menu</mat-toolbar>
    <mat-nav-list>
      <a mat-list-item>Link 1</a>
      <a mat-list-item>Link 2</a>
      <a mat-list-item>Link 3</a>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content>
    <mat-toolbar color="accent">
      @if (isHandset$ | async) {
      <button
        type="button"
        aria-label="Toggle sidenav"
        mat-icon-button
        (click)="drawer.toggle()">
        <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
      </button>
      }


      <span class="ref"></span> <!-- This is CSS for whitelabel tenant name -->
      <span class="example-spacer"></span>
      <mat-menu class="dropdown-menu" #appMenu="matMenu">
        <button mat-menu-item (click)="logout()">
          <mat-icon class="add-button">logout</mat-icon>
          Logout
        </button>
      </mat-menu>
      <button mat-icon-button [matMenuTriggerFor]="appMenu">
        <mat-icon>more_vert</mat-icon>
      </button>

    </mat-toolbar>
    <ng-content></ng-content>
  </mat-sidenav-content>
</mat-sidenav-container>
